:root {
  --success: '#444';
}

/* Toast container */
.toast-container {
  position: fixed;
  margin: 0.5rem;
  width: 100%;
  max-width: 520px;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 100000;
}

/* Toast  */
.toast {
  position: relative;
  box-sizing: border-box;
  padding: 2rem;
  background: #020220;
  border: 1px solid #333;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
  text-align: center;
  overflow: hidden;
  font-family: 'Brown';
}

/* Toast container positions */
.toast-container[data-position^='top-'] {
  top: 0;
}

.toast-container[data-position^='bottom-'] {
  bottom: 0;
}

.toast-container[data-position$='-left'] {
  left: 0;
}

.toast-container[data-position$='-right'] {
  right: 0;
}

.toast-container[data-position$='-center'] {
  left: 50%;
  transform: translateX(-50%);
}

.toast-container[data-position='center'] {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Toast close button */
.toast.can-close::after {
  position: absolute;
  content: '\00D7';
  top: 2px;
  right: 0.5rem;
}
